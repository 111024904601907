// Packages
import React from 'react';

// Components
import Layout from '~components/layouts/Default';

// Types
import type { SiteMetadata, Location } from '~types/Gatsby';
import Container from '~components/common/Container';
import PageHeader from '~components/common/PageHeader';
import { Link } from 'gatsby';

interface DataProps {
  site: {
    siteMetadata: SiteMetadata;
  };
}

export interface ContentCMS {
  id: string;
  title: string;
  markdown: string;
}

export interface Content extends ContentCMS {}

export default function Page({ location }: { data: DataProps; location: Location }) {
  return (
    <Layout location={location} title="Entspannungshelden App - Rechtliches" desc="">
      <PageHeader
        title="Rechtliches zur Entspannungshelden App"
        breadcrumb={[
          { title: 'App', link: '/app/', current: false },
          { title: 'Rechtliches', link: '/app/rechtliches', current: true }
        ]}
      />
      <Container>
        <div className="prose prose-lg mx-auto mt-6">
          <ul className="list-none">
            <li>
              <Link to="/app/rechtliches/impressum">Impressum</Link>
            </li>
            <li>
              <Link to="/app/rechtliches/datenschutz">Datenschutz</Link>
            </li>
            <li>
              <Link to="/app/rechtliches/haftungsausschluss">Haftungsausschluss</Link>
            </li>
            <li>
              <Link to="/app/rechtliches/agb">AGB</Link>
            </li>
          </ul>{' '}
        </div>
      </Container>
    </Layout>
  );
}
